<template>
    <div>
		<a-row :gutter="24" type="flex">
			<a-col :span="24" class="mb-24">
            	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">

                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">Bandeja de Entrada</h5>
                            </a-col>
                            <a-col :span="24" :md="12" class="d-flex align-items-center justify-content-end">
                                <!-- <a-button type="primary" @click="obtenerDocumentos(state.filters)">Recargar Lista</a-button> -->
                            </a-col>
                        </a-row>
                    </template>
                    
                    <div>
                        <TablaListado  />
                    </div>
                </a-card>

			</a-col>
		</a-row>
    </div>
</template>

<script>
    import { defineComponent, reactive, computed } from 'vue';
    import { useStore } from "vuex";

    import TablaListado from '@/components/tenant/bandeja_entrada/TablaListado';
    
    export default defineComponent({
        layout: {
            name: 'TenantLayout',
            props: {}
        },  

        components: { TablaListado },

        setup() {
            const store = useStore();

            const state  = reactive({
                consulta : {
                    pag: {
                        total: 0,
                        current: 1,
                        pageSize: Number(process.env.VUE_APP_PAG_SIZE),                 
                    },
                    filters: {},
                    sorter: {},
                    data: [],
                }
            });
            
            // actions
            const obtenerDocumentos = data => { 
                store.dispatch('bandeja_entrada/obtenerDocumentos', data).then( 
                    response => { },
                    error  => {
                        let message = error.response ?  error.response.data.error : error.message;
                        AntN.error('Bandeja de entrada', message);
                    })
            }
            
            obtenerDocumentos(state.consulta);

            return { }
        }
    });
</script>