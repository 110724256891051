<template>
    <div>
        <a-divider orientation="left">Filtros</a-divider>

        <div class="m-3">
            <FiltradorTabla @filtrar="filtrarDocumentos" />
        </div>

        <a-table
            :columns="columnas"
            :data-source="documentosPaginado.data"
            :pagination="documentosPaginado.pag"
            :row-key="record => record.id"
            :loading="isLoading"
            @change="handleTableChange"
        >
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'proveedor'">
                    <span :class="record.proveedor ? '' : 'text-danger' ">{{ record.proveedor_id }}</span>
                </template>

                <template v-if="column.dataIndex === 'empresa'">
                    <span >{{ record.empresa_id }}</span>
                </template>

                <template v-if="column.dataIndex === 'tipo_documento'">
                    <span>{{ record.tipo_documento.descripcion }} {{ record.serie }}</span> - <span>{{record.correlativo}}</span>
                </template>

                <template v-if="column.dataIndex === 'fecha'">
                    <span>{{ record.fecha }}</span>
                </template>

                <template v-if="column.dataIndex === 'total'">
                    <span>{{record.total}}</span>
                </template>

                <template v-if="column.dataIndex === 'tipo_moneda'">
                    <span>{{ record.tipo_moneda.codigo }}</span>
                </template>

                <template v-if="column.dataIndex === 'estado_comercial'">
                    <span> {{ record.data_estado_comercial.descripcion }}</span>
                </template>
            </template>
        </a-table>
    </div>
</template>

<script>
    import { defineComponent, computed, reactive, ref } from 'vue';
    import { useStore } from "vuex";
    
    import FiltradorTabla from '@/components/tenant/bandeja_entrada/FiltradorTabla';

    const columnas = [
        { title: 'RUC EMISOR', dataIndex: 'proveedor', sorter: true},
        { title: 'RUC ADQUIRIENTE', dataIndex: 'empresa', sorter: true},
        { title: 'TIPO CPE', dataIndex: 'tipo_documento',},
        { title: 'FECHA CPE', dataIndex: 'fecha', sorter: true, },
        { title: 'MONTO CPE', dataIndex: 'total', sorter: true, },
        { title: 'FORMA DE PAGO', dataIndex: 'forma_pago', sorter: true, },
        { title: 'ESTADO TRIBUTARIO', dataIndex: 'descripcion_cpe', sorter: true, },
        { title: 'ESTADO COMERCIAL', dataIndex: 'estado_comercial', sorter: true, },
    ];
    
    export default defineComponent({
        components: { FiltradorTabla},

        setup() {
            const store = useStore();

            const formState = reactive({
                documento: {},
            });

            const formRef = ref();

            // getters
            const isLoading = computed(() => store.getters["bandeja_entrada/isLoading"]);
            const isDeleting = computed(() => store.getters["bandeja_entrada/isDeleting"]);
            const documentosPaginado = computed(() => store.getters["bandeja_entrada/documentosPaginado"]);

            // actions
            const handleTableChange = (pag, filter, sorter) => {
                let { data, filters } = documentosPaginado.value;

                store.dispatch('bandeja_entrada/obtenerDocumentosPaginado', { data, pag, filters, sorter });
            };
            
            const filtrarDocumentos = (filters) => {
                let consulta = { ...documentosPaginado.value };
                consulta.filters = filters;
                
                store.dispatch('bandeja_entrada/obtenerDocumentosPaginado', consulta );
            }

            return { formState, formRef, isLoading, columnas, handleTableChange, documentosPaginado, filtrarDocumentos}
        }
    });
</script>